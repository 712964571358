<template>
  <div class="dem-settings">
    <SeasonPatternModal :id="modalsId.SEASON_PATTERN" />
    <v-card>
      <v-tabs
        v-model="tabModel"
        class="sl-tabs"
      >
        <v-tab
          v-for="item in tabItems"
          :key="item.value"
          :href="item.href"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tabModel"
        class="dem-settings__content-wrap"
      >
        <v-tab-item
          v-for="item in tabItems"
          :key="item.value"
          :value="item.value"
        >
          <div class="dem-settings__content">
            <SlAlertLine
              v-if="showWarning"
              type="warn"
              :accent="settingsWarning"
            />
            <component
              :is="item.component"
              v-bind="tabProps"
              @form-focus="$emit('form-focus')"
              @select-change="setSetting"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import ForecastingTab from '@/components/Demand/DemandSettings/Tabs/ForecastingTab.vue';
import KPItab from '@/components/Demand/DemandSettings/Tabs/KPItab.vue';
import InventoryTab from '@/components/Demand/DemandSettings/Tabs/InventoryTab.vue';
import ModelTab from '@/components/Demand/DemandSettings/Tabs/ModelTab.vue';
import SeasonPatternModal from '@/components/Modals/SeasonPattern/Index.vue';
import { treeItemStates } from '@/config/demand/tree/tree.config';
import { nodeFlags } from '@/config/shared/fgs.config';
import {
  forecastTabItems,
  forecastingKeys,
  forecastingRows,
  useModelFromIcons,
  tabKeys
} from '@/config/demand/demandSettings.config';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';
import cloneObject from '@/helpers/utils/cloneObject';
import { projectRedirect } from '@/helpers/router';
import { ampli } from '@/plugins/product/ampli';

export default {
  name: 'DemandSettings',
  components: {
    ForecastingTab,
    KPItab,
    InventoryTab,
    ModelTab,
    SeasonPatternModal
  },
  mixins: [modal],
  data() {
    return {
      modalsId,
      tabModel: null,
      ignoreBeforeFirstValue: 4294967295,
      ignoreSettingKeys: [
        forecastingKeys.IGNORE_BEFORE,
        forecastingKeys.STATUS,
        forecastingKeys.SEASONAL_PATTERN
      ],
      warnStates: [
        treeItemStates.WARNING,
        treeItemStates.WARN_MIXED,
        treeItemStates.WARN_ABANDONED
      ]
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      seasonPatternsModel: (state) => state.demand.seasonPattern.seasonal_patterns_model,
      nodeTable: (state) => state.demand.table.table,
      childSet: (state) => state.demand.settings.settings?.baseHierSets,
      parentSet: (state) => state.demand.settings.settings?.hierSets,
      inheritedMassTopDown: (state) => state.demand.settings.settings?.inheritedMassTopDown,
      settingsWarning: (state) => state.demand.settings.settings?.settingsWarning
    }),
    ...mapGetters({
      dates: 'project/projectDates',
      nodeState: 'demand/tree/activeNodeState',
      nodeMultipleState: 'demand/tree/activeNodeMultipleState',
      useModelFromStatus: 'demand/table/useModelFromStatus',
      wantInvModel: 'project/wantInvModel',
      nodeFlagByShift: 'demand/table/nodeFlagByShift',
      fgsByShift: 'demand/tree/fgsByShift',
      editableForSomeChildren: 'demand/tree/editableForSomeChildren',
      nRealPoints: 'project/nRealPoints',
      itemCodes: 'project/itemCodes',
      holidays: 'project/holidaysSet'
    }),
    showWarning() {
      return this.settingsWarning && this.tabModel === tabKeys.FORECASTING;
    },
    modelIconsConfig() {
      return useModelFromIcons(this);
    },
    forecastRows() {
      return forecastingRows(this);
    },
    tabItems() {
      return forecastTabItems(this);
    },
    tabProps() {
      return {
        settings: this.settingsSet,
        availableInventory: this.wantInvModel && this.isEntry,
        message: this.$t('Main.Ui.lbNotAvModel')
      };
    },
    isEntry() {
      return !!this.nodeFlagByShift(nodeFlags.IS_ENTRY);
    },
    modelIcon() {
      return this.modelIconsConfig[this.useModelFromStatus] || {};
    },
    settingsSet() {
      const {
        IS_MODAL_ACTIVE,
        TOP_DOWN,
        MODEL,
        IGNORE_BEFORE,
        ZERO_LOST,
        IGNORE_LOST_DAYS,
        AUTODETECT_OUTLIERS,
        USE_PRICE,
        CULTURE,
        SEASONAL_PATTERN,
        USE_PROMO,
        CLONE,
        ADJUST_LEVEL,
        DEMAND_SENSING,
        ALLOW_BOM,
        HS_APPROVAL,
        STATUS
      } = forecastingKeys;

      return [
        {
          name: this.forecastRows[IS_MODAL_ACTIVE].title,
          path: IS_MODAL_ACTIVE,
          state: this.parentSet[IS_MODAL_ACTIVE],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[IS_MODAL_ACTIVE].items[this.childSet[IS_MODAL_ACTIVE]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[IS_MODAL_ACTIVE].items['Yes'],
              value: 'Yes'
            },
            {
              text: this.forecastRows[IS_MODAL_ACTIVE].items['No'],
              value: 'No'
            }
          ]
        },
        {
          name: this.forecastRows[TOP_DOWN].title,
          path: TOP_DOWN,
          state: this.parentSet[TOP_DOWN],
          choice: [
            {
              text: this.inheritedMassTopDown === 'none'
                ? this.$t('Props.Inherit2', {
                  1: this.forecastRows[TOP_DOWN].items[this.childSet[TOP_DOWN]]
                })
                : this.$t(`Props.TopDown.${this.inheritedMassTopDown}`),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[TOP_DOWN].items['Bottom-up'],
              value: 'Bottom-up',
              icon: 'up_arrow'
            },
            {
              text: this.forecastRows[TOP_DOWN].items['Top-down'],
              value: 'Top-down',
              icon: 'down_arrow'
            },
            {
              text: this.forecastRows[TOP_DOWN].items['Top-down_child'],
              value: 'Top-down_child',
              icon: 'double_down_arrow'
            }
          ]
        },
        {
          name: this.forecastRows[MODEL].title,
          path: MODEL,
          state: this.parentSet[MODEL],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[MODEL].items[this.childSet[MODEL]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[MODEL].items['Automatic'],
              value: 'Automatic',
              icon: 'hand'
            },
            {
              text: this.forecastRows[MODEL].items['Seasonal'],
              value: 'Seasonal',
              icon: 'hand'
            },
            {
              text: this.forecastRows[MODEL].items['Linear'],
              value: 'Linear',
              icon: 'hand'
            },
            {
              text: this.forecastRows[MODEL].items['Constant'],
              value: 'Constant',
              icon: 'hand'
            },
            {
              text: this.forecastRows[MODEL].items['Infrequent'],
              value: 'Infrequent',
              icon: 'hand'
            },
            {
              text: this.forecastRows[MODEL].items['Preorder'],
              value: 'Preorder',
              icon: 'hand'
            },
            {
              text: this.forecastRows[MODEL].items['Auto-preorder'],
              value: 'Auto-preorder',
              icon: 'hand'
            },
            {
              text: this.forecastRows[MODEL].items['Auto-termination'],
              value: 'Auto-termination',
              icon: 'hand'
            }
          ]
        },
        {
          name: this.forecastRows[IGNORE_BEFORE].title,
          path: IGNORE_BEFORE,
          state: this.parentSet[IGNORE_BEFORE] === this.ignoreBeforeFirstValue
            ? 0
            : this.dates[this.parentSet[IGNORE_BEFORE]].id + 1,
          choice: this.dates.slice(0, this.nRealPoints).reduce((acc, item) => {
            acc.push({
              text: item.val,
              value: item.id + 1,
              icon: 'stopwatch'
            });

            return acc;
          }, [{
            text: this.$t('Props.Inherit2', {
              1: this.dates[this.childSet[IGNORE_BEFORE]]?.val || ''
            }),
            value: 0
          }])
        },
        {
          name: this.forecastRows[ZERO_LOST].title,
          path: ZERO_LOST,
          state: this.parentSet[ZERO_LOST],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[ZERO_LOST].items[this.childSet[ZERO_LOST]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[ZERO_LOST].items['Yes'],
              value: 'Yes',
              icon: 'slashed-0'
            },
            {
              text: this.forecastRows[ZERO_LOST].items['No'],
              value: 'No'
            }
          ]
        },
        {
          name: this.forecastRows[IGNORE_LOST_DAYS].title,
          path: IGNORE_LOST_DAYS,
          state: this.parentSet[IGNORE_LOST_DAYS],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[IGNORE_LOST_DAYS].items[this.childSet[IGNORE_LOST_DAYS]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[IGNORE_LOST_DAYS].items['Yes'],
              value: 'Yes'
            },
            {
              text: this.forecastRows[IGNORE_LOST_DAYS].items['No'],
              value: 'No'
            }
          ]
        },
        {
          name: this.forecastRows[AUTODETECT_OUTLIERS].title,
          path: AUTODETECT_OUTLIERS,
          state: this.parentSet[AUTODETECT_OUTLIERS],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[AUTODETECT_OUTLIERS].items[this.childSet[AUTODETECT_OUTLIERS]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[AUTODETECT_OUTLIERS].items['Yes'],
              value: 'Yes'
            },
            {
              text: this.forecastRows[AUTODETECT_OUTLIERS].items['No'],
              value: 'No'
            }
          ]
        },
        {
          name: this.forecastRows[USE_PRICE].title,
          path: USE_PRICE,
          state: this.parentSet[USE_PRICE],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[USE_PRICE].items[this.childSet[USE_PRICE]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[USE_PRICE].items['Auto'],
              value: 'Auto'
            },
            {
              text: this.forecastRows[USE_PRICE].items['Yes'],
              value: 'Yes'
            },
            {
              text: this.forecastRows[USE_PRICE].items['No'],
              value: 'No'
            }
          ]
        },
        {
          name: this.forecastRows[CULTURE].title,
          path: CULTURE,
          state: this.parentSet[CULTURE],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.childSet[CULTURE] === 'None'
                  ? this.forecastRows[CULTURE].items['None']
                  : this.childSet[CULTURE]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[CULTURE].items['None'],
              value: 'None'
            },
            ...this.holidays
          ]
        },
        {
          name: this.forecastRows[SEASONAL_PATTERN].title,
          path: SEASONAL_PATTERN,
          state: this.parentSet[SEASONAL_PATTERN].id !== undefined && this.parentSet[SEASONAL_PATTERN].id !== this.ignoreBeforeFirstValue
            ? this.parentSet[SEASONAL_PATTERN].id
            : this.parentSet[SEASONAL_PATTERN].state,
          choice: this.seasonPatternsModel.map(item => {
            const choiceItem = {
              text: item.name,
              value: item.id ?? item.state
            };

            if (item.state === 'inherit') {
              const childItem = this.childSet[SEASONAL_PATTERN];
              const inheritUnique = childItem.id ?? childItem.state;
              const inheritItem = this.seasonPatternsModel.find(item => {
                if (childItem.id !== undefined) {
                  return item.id === inheritUnique;
                }

                return item.state === inheritUnique;
              });

              choiceItem.text = this.$t('Props.Inherit2', {
                1: inheritItem?.name || ''
              });
            }

            return choiceItem;
          }, []),
          button: {
            icon: 'pencil.svg',
            callback: this.goToSeasonPatterns
          }
        },
        {
          name: this.forecastRows[USE_PROMO].title,
          path: USE_PROMO,
          state: this.parentSet[USE_PROMO],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[USE_PROMO].items[this.childSet[USE_PROMO]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[USE_PROMO].items['Yes'],
              value: 'Yes'
            },
            {
              text: this.forecastRows[USE_PROMO].items['No'],
              value: 'No'
            }
          ],
          button: {
            icon: 'monitor.svg',
            callback: this.openPromotions
          }
        },
        {
          name: this.forecastRows[CLONE].title,
          path: CLONE,
          state: (this.parentSet[CLONE] === 'Inherit' || this.parentSet[CLONE] === '') && this.childSet
            ? this.childSet[CLONE] !== ''
              ? this.$t('Props.Inherit2', { 1: this.childSet[CLONE] })
              : ''
            : this.parentSet[CLONE],
          choice: this.itemCodes,
          icon: this.modelIcon
        },
        {
          name: this.forecastRows[ADJUST_LEVEL].title,
          path: ADJUST_LEVEL,
          state: this.parentSet[ADJUST_LEVEL],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[ADJUST_LEVEL].items[this.childSet[ADJUST_LEVEL]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[ADJUST_LEVEL].items['Yes'],
              value: 'Yes'
            },
            {
              text: this.forecastRows[ADJUST_LEVEL].items['No'],
              value: 'No'
            }
          ]
        },
        {
          name: this.forecastRows[DEMAND_SENSING].title,
          path: DEMAND_SENSING,
          state: this.parentSet[DEMAND_SENSING],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[DEMAND_SENSING].items[this.childSet[DEMAND_SENSING]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[DEMAND_SENSING].items['Yes'],
              value: 'Yes'
            },
            {
              text: this.forecastRows[DEMAND_SENSING].items['No'],
              value: 'No'
            }
          ]
        },
        {
          name: this.forecastRows[ALLOW_BOM].title,
          path: ALLOW_BOM,
          state: this.parentSet[ALLOW_BOM],
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.forecastRows[ALLOW_BOM].items[this.childSet[ALLOW_BOM]]
              }),
              value: 'Inherit'
            },
            {
              text: this.forecastRows[ALLOW_BOM].items['Yes'],
              value: 'Yes'
            },
            {
              text: this.forecastRows[ALLOW_BOM].items['No'],
              value: 'No',
              icon: 'cart'
            }
          ]
        },
        {
          name: this.forecastRows[HS_APPROVAL].title,
          path: HS_APPROVAL,
          state: this.parentSet[HS_APPROVAL].state === 'Inherit'
            ? {
              text: this.$t('Props.Inherit2', {
                1: this.childSet[HS_APPROVAL].val
              }),
              value: this.parentSet[HS_APPROVAL].state
            }
            : this.parentSet[HS_APPROVAL].val,
          min: 0,
          max: 99,
          choice: [
            {
              text: this.$t('Props.Inherit2', {
                1: this.childSet[HS_APPROVAL].val
              }),
              value: 'Inherit'
            }
          ]
        },
        {
          name: this.forecastRows[STATUS].title,
          path: STATUS,
          state: this.nodeMultipleState
            ? ''
            : this.warnStates.indexOf(this.nodeState) !== -1
              ? treeItemStates.WARNING
              : this.nodeState,
          choice: [
            {
              text: '',
              value: ''
            },
            {
              text: this.$t('Props.CheckedState.Normal'),
              value: treeItemStates.NORMAL
            },
            {
              text: this.$t('Props.CheckedState.Attention'),
              value: treeItemStates.WARNING,
              icon: 'warn_collored'
            },
            {
              text: this.$t('Props.CheckedState.Checked'),
              value: treeItemStates.CHECKED,
              icon: 'approve_green'
            }
          ].slice(this.nodeMultipleState ? 0 : 1)
        }
      ];
    },
    actionByTab() {
      return {
        [tabKeys.MODAL]: this.fetchModelTab,
        [tabKeys.INVENTORY]: this.fetchInv,
        [tabKeys.KPI]: this.fetchKPI
      };
    }
  },
  watch: {
    tabModel(val) {
      this.updateTab(val);
    },
    nodeTable: {
      handler() {
        this.updateTab(this.tabModel);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      fetchModelTab: 'model/fetchModelTab',
      fetchInv: 'invParam/fetchInv',
      fetchKPI: 'kpi/fetchKPI',
      updateSettings: 'demand/tree/updateSettings',
      toggleItemState: 'demand/tree/toggleItemState'
    }),
    updateTab(tab) {
      this.actionByTab[tab] && this.actionByTab[tab]();
    },
    goToSeasonPatterns() {
      projectRedirect({
        name: routeNames.SEASONALITY_PATTERNS
      });
    },
    openPromotions() {
      projectRedirect({
        name: routeNames.PROMOTIONS
      });
    },
    setSetting({ val, condition }) {
      if (Array.isArray(val)) {
        return;
      }

      const initialSettings = cloneObject(this.parentSet);

      if (condition.path === forecastingKeys.STATUS && val) {
        return this.toggleItemState(val);
      }

      if (condition.path === forecastingKeys.IGNORE_BEFORE) {
        initialSettings[condition.path] = val === 0
          ? this.ignoreBeforeFirstValue
          : val - 1;
      }

      if (condition.path === forecastingKeys.SEASONAL_PATTERN) {
        if (['inherit', 'auto'].includes(val)) {
          initialSettings[condition.path] = {
            state: val
          };
        } else {
          initialSettings[condition.path] = {
            state: 'custom',
            id: val
          };
        }
      }

      if (!this.ignoreSettingKeys.includes(condition.path)) {
        initialSettings[condition.path] = val;
      }

      if (condition.path === forecastingKeys.HS_APPROVAL) {
        initialSettings[condition.path] = val.value ?? +val;
      }

      this.updateSettings({
        [condition.path]: initialSettings[condition.path]
      });

      ampli.forecastingParametersOverridden({
        forecastingParameterName: this.forecastRows[condition.path].title
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/demSettings/demandSettings.scss";
</style>

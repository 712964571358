export const tabKeys = {
  FORECASTING: 'forecasting',
  MODAL: 'modal',
  INVENTORY: 'inventory',
  KPI: 'kpis'
};

export const forecastTabItems = (ctx) => ([
  {
    title: ctx.$t('Main.Ui.tabItem:at'),
    component: 'ForecastingTab',
    href: `#${tabKeys.FORECASTING}`,
    value: tabKeys.FORECASTING
  },
  {
    title: ctx.$t('Main.Ui.tabModel:at'),
    component: 'ModelTab',
    href: `#${tabKeys.MODAL}`,
    value: tabKeys.MODAL
  },
  {
    title: ctx.$t('Main.Ui.tabInventory:at'),
    component: 'InventoryTab',
    href: `#${tabKeys.INVENTORY}`,
    value: tabKeys.INVENTORY
  },
  {
    title: ctx.$t('Main.Ui.tabKpi:at'),
    component: 'KPItab',
    href: `#${tabKeys.KPI}`,
    value: tabKeys.KPI
  }
]);

export const forecastingKeys = {
  IS_MODAL_ACTIVE: 'isModelActive',
  TOP_DOWN: 'top-down',
  MODEL: 'model',
  IGNORE_BEFORE: 'ignoreBefore',
  ZERO_LOST: 'zeroLost',
  IGNORE_LOST_DAYS: 'ignoreLostDays',
  AUTODETECT_OUTLIERS: 'autodetectOutliers',
  USE_PRICE: 'usePrice',
  CULTURE: 'culture',
  USE_PROMO: 'usePromo',
  SEASONAL_PATTERN: 'hsSeasonalityPattern',
  CLONE: 'clone',
  ADJUST_LEVEL: 'adjustLevel',
  DEMAND_SENSING: 'demandSensing',
  ALLOW_BOM: 'allowBom',
  HS_APPROVAL: 'hsApproval',
  STATUS: 'status'
};

export const forecastingRows = (ctx) => ({
  [forecastingKeys.IS_MODAL_ACTIVE]: {
    title: ctx.$t('Main.Ui.lbIsModelActive'),
    items: {
      'Yes': ctx.$t('Props.IsModelActive.Y'),
      'No': ctx.$t('Props.IsModelActive.N')
    }
  },
  [forecastingKeys.TOP_DOWN]: {
    title: ctx.$t('Main.Ui.lbTopDown'),
    items: {
      'Bottom-up': ctx.$t('Props.TopDown.Bu'),
      'Top-down': ctx.$t('Props.TopDown.Td'),
      'Top-down_child': ctx.$t('Props.TopDown.Td2')
    }
  },
  [forecastingKeys.MODEL]: {
    title: ctx.$t('Main.Ui.lbModel'),
    items: {
      'Automatic': ctx.$t('Props.Model.Auto'),
      'Auto-preorder': ctx.$t('Props.Model.Auto2'),
      'Auto-termination': ctx.$t('Props.Model.AutoDying'),
      'Constant': ctx.$t('Props.Model.Const'),
      'Linear': ctx.$t('Props.Model.Linear'),
      'Seasonal': ctx.$t('Props.Model.Seasonal'),
      'Infrequent': ctx.$t('Props.Model.Infrequent'),
      'Preorder': ctx.$t('Props.Model.Preorder')
    }
  },
  [forecastingKeys.IGNORE_BEFORE]: {
    title: ctx.$t('Main.Ui.lbIgnoreFrom')
  },
  [forecastingKeys.ZERO_LOST]: {
    title: ctx.$t('Main.Ui.lbZeroLost'),
    items: {
      'Yes': ctx.$t('Props.ZeroLost.Y'),
      'No': ctx.$t('Props.ZeroLost.N')
    }
  },
  [forecastingKeys.IGNORE_LOST_DAYS]: {
    title: ctx.$t('Main.Ui.lbIgnoreLostDays'),
    items: {
      'Yes': ctx.$t('Props.IgnoreLostDays.Y'),
      'No': ctx.$t('Props.IgnoreLostDays.N')
    }
  },
  [forecastingKeys.AUTODETECT_OUTLIERS]: {
    title: ctx.$t('Main.Ui.lbDetectOutliers'),
    items: {
      'Yes': ctx.$t('Props.IgnoreLostDays.Y'),
      'No': ctx.$t('Props.IgnoreLostDays.N')
    }
  },
  [forecastingKeys.USE_PRICE]: {
    title: ctx.$t('Main.Ui.lbPriceElasticity'),
    items: {
      'Auto': ctx.$t('Props.PriceElasticity.Auto'),
      'Yes': ctx.$t('Props.PriceElasticity.Y'),
      'No': ctx.$t('Props.PriceElasticity.N')
    }
  },
  [forecastingKeys.CULTURE]: {
    title: ctx.$t('Main.Ui.lbCalendar'),
    items: {
      'None': ctx.$t('Props.NoCulture'),
      'Australia': 'Australia',
      'Brazil': 'Brazil',
      'Canada': 'Canada',
      'Japan': 'Japan',
      'Lebanon': 'Lebanon',
      'Mexico': 'Mexico',
      'New Zealand': 'New Zealand',
      'Saudi Arabia': 'Saudi Arabia',
      'USA': 'USA'
    }
  },
  [forecastingKeys.SEASONAL_PATTERN]: {
    title: ctx.$t('Main.Ui.lbSeasonalityPattern'),
    items: {
      'auto': ctx.$t('Main.Auto')
    }
  },
  [forecastingKeys.USE_PROMO]: {
    title: ctx.$t('Main.Ui.lbPromotions'),
    items: {
      'Yes': ctx.$t('Props.Promo.Y'),
      'No': ctx.$t('Props.Promo.N')
    }
  },
  [forecastingKeys.CLONE]: {
    title: ctx.$t('Main.Ui.lbCloneModel')
  },
  [forecastingKeys.ADJUST_LEVEL]: {
    title: ctx.$t('Main.Ui.lbAdjustLevel'),
    items: {
      'Yes': ctx.$t('Props.AdjustLevel.Y'),
      'No': ctx.$t('Props.AdjustLevel.N')
    }
  },
  [forecastingKeys.DEMAND_SENSING]: {
    title: ctx.$t('Main.Ui.acClearMultipleDemandSensing'),
    items: {
      'Yes': ctx.$t('Props.DemandSensing.Y'),
      'No': ctx.$t('Props.DemandSensing.N')
    }
  },
  [forecastingKeys.ALLOW_BOM]: {
    title: ctx.$t('Main.Ui.lbAllowBom'),
    items: {
      'Yes': ctx.$t('Props.Bom.Y'),
      'No': ctx.$t('Props.Bom.N')
    }
  },
  [forecastingKeys.HS_APPROVAL]: {
    title: ctx.$t('Main.Ui.lbApprovalHorizon')
  },
  [forecastingKeys.STATUS]: {
    title: ctx.$t('Main.Ui.lbChannelCheckedState')
  }
});

export const tabsTableTitles = (ctx, period) => ({
  'Main.Cols.InvVal': ctx.$t('Import.Combo.BalanceValue'),
  'Main.Cols.DaysOfSupply': ctx.$t('Main.Cols.DaysOfSupply2'),
  'Main.Cols.DeadInvVal': ctx.$t('Main.Cols.DeadInvValue'),
  'Main.Cols.Turnover': `${ctx.$t('Main.Cols.TurnoverCap')} | ${ctx.$t('Main.Cols.Turnover')}`,
  'Main.Cols.ToSell': `${ctx.$t('Main.Cols.TurnoverCap')} | ${ctx.$t('Main.Cols.ToSell')}`,
  'Main.Cols.Turnearn': ctx.$t('Main.Cols.TurnEarn'),
  'Main.Cols.RevenueLast': ctx.$t('Main.Cols.RevLast'),
  'Main.Cols.RevenueNext': ctx.$t('Main.Cols.RevNext'),
  'Main.Cols.QtyLast': ctx.$t('Main.Cols.QuantityLast'),
  'Main.Cols.QtyNext': ctx.$t('Main.Cols.QuantityNext'),

  'Main.Value.ONHAND': ctx.$t('Main.Cols.OnHand'),
  'Main.Value.OH_DAYS_OF_SUPPLY': ctx.$t('Main.Cols.DaysOfSupply2'),
  'Main.Value.OH_MONTHS_OF_SUPPLY': ctx.$t('Main.Cols.MonthsOfSupply'),
  'Main.Value.BACKORDER': ctx.$t('Main.Cols.Backorder'),
  'Main.Value.ONORDER': ctx.$t('Main.Cols.OnOrder'),
  'Main.Value.LEADTIME': ctx.$t('Main.Cols.LeadTime'),
  'Main.Value.LEADTIME_VARIANCE': ctx.$t('Main.Cols.LeadTimeVariance'),
  'Main.Value.ORDER_CYCLE': ctx.$t('Main.Cols.OrderCycle', { 1: period }),
  'Main.Value.ITEM_ORDERING_DAYS': ctx.$t('Main.Cols.OrderingDays'),
  'Main.Value.MINBUNDLE': ctx.$t('Main.Cols.MinLot'),
  'Main.Value.MAXBUNDLE': ctx.$t('Main.Cols.MaxLot'),
  'Main.Value.BOXROUND': ctx.$t('Main.Cols.BoxRound'),
  'Main.Value.SERVICE_LEVEL': ctx.$t('Main.Cols.ServiceLevel'),
  'Main.Value.SAFESTOCK': ctx.$t('Main.Cols.SafeStock'),
  'Main.Value.SHELF_LIFE': ctx.$t('Main.Cols.ShelfLife', { 1: period }),
  'Main.Value.SHELF_DISCARD': ctx.$t('Main.Cols.ShelfDiscard'),
  'Main.Value.ITEM_SALES_PRICE': ctx.$t('Main.Cols.OrderUnitPrice'),
  'Main.Value.PURCHASE_PRICE': ctx.$t('Main.Cols.PurchasePrice', { 1: '', 3: '' }),
  'Main.Value.BALANCE_PRICE': ctx.$t('Main.Cols.BalancePrice', { 1: '', 3: '' }),
  'Main.Value.MARGIN': ctx.$t('Main.Cols.Margin'),
  'Main.Value.TURNEARN': ctx.$t('Main.Cols.TurnEarn'),
  'Main.Value.ORDER_NOW_HEAD': ctx.$t('Main.Cols.OrderNow'),
  'Main.Value.PURCHASE_VALUE': ctx.$t('Main.Cols.PurchaseValue', { 1: '', 3: '' }),
  'Main.Value.STOCKOUT': ctx.$t('Main.Cols.Stockout'),
  'Main.Value.OVERSTOCK': ctx.$t('Main.Cols.Overstock'),
  'Main.Value.REORDER_POINT': ctx.$t('Main.Cols.ReorderMin'),
  'Main.Value.REORDER_AMOUNT': ctx.$t('Main.Cols.ReorderMax')
});

export const useModelFromIcons = (ctx) => ({
  wrong: {
    icon: 'red_warning',
    title: () => ctx.$t('Error.UnknownItem')
  },
  use_from_myself: {
    icon: 'red_warning',
    title: () => ctx.$t('Error.UseModelFromMyself')
  },
  cyclic_dir_fault: {
    icon: 'ouroboros',
    title: (itemCode) => ctx.$t('Error.Loop', { 1: itemCode })
  },
  cyclic: {
    icon: 'ouroboros',
    title: (itemCode) => ctx.$t('Error.Loop', { 1: itemCode })
  },
  partial_channel_match: {
    icon: '',
    mismatch: true,
    title: ctx.$t('Error.PartialChannelMatch')
  },
  channel_mismatch: {
    icon: '',
    mismatch: true,
    title: ctx.$t('Error.NoChannelMatch')
  }
});
